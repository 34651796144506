export const ShareSocial = [
  {
    id: 1,
    icon: "fa-brands fa-facebook-f",
    url: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    id: 4,
    icon: "fa-brands fa-whatsapp",
    url: "https://api.whatsapp.com/send?text=",
  },
  {
    id: 3,
    icon: "fa-brands fa-x-twitter",
    url: "https://twitter.com/intent/tweet?url=",
  },
];
